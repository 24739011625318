import { Button, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { setToken, setWebHost } from "../../utils/storage";
import { useNavigate } from "react-router-dom";

const WsopText = () => {
  const navigate = useNavigate();
  const handleWSOP = () => {
    console.log("process.env.REACT_APP_WSOP_API_KEY ===> ", process.env.REACT_APP_WSOP_API_KEY)
    setToken(process.env.REACT_APP_WSOP_API_KEY as any);
    setWebHost("WSOP");
    // urlBase("LFIEnYlU8eE2OVtsytNyjrHd61QjKxNK")
    navigate("/sign");
  };
  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <Typography variant="body14Regular">
          The Workload Security Option Pack (WSOP) is a specialized solution
          designed to enhance the security and performance of your cryptographic
          operations. By integrating seamlessly with Hardware Security Modules
          (HSMs), WSOP enables organizations to efficiently manage high-demand
          cryptographic workloads, ensuring data integrity, privacy, and
          compliance with stringent security standards.
        </Typography>
      </Grid>
      <Grid item lg={6}>
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          Key Features
        </Typography>
        <List style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <ListItem style={{ display: "list-item" }}>
            <Typography variant="body14Regular">
              <strong>Scalable Security:</strong> WSOP enhances the capacity of
              HSMs, allowing them to handle increased cryptographic workloads
              without compromising on security.
            </Typography>
          </ListItem>
          <ListItem style={{ display: "list-item" }}>
            <Typography variant="body14Regular">
              <strong>Optimized Performance:</strong> It intelligently
              distributes cryptographic operations across available resources,
              ensuring optimal performance even during peak loads.
            </Typography>
          </ListItem>
          <ListItem style={{ display: "list-item" }}>
            <Typography variant="body14Regular">
              <strong>Compliance & Flexibility: </strong> IWSOP supports various
              security protocols and compliance requirements, providing the
              flexibility needed to meet industry-specific regulations.
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item lg={6}>
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          Use Case
        </Typography>
        <Typography variant="body14Regular">
          The company manages large amounts of transactions daily, requiring
          high-performance cryptographic processing to protect sensitive
          customer data. Areas of implementation:
        </Typography>
        <List style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <ListItem style={{ display: "list-item", paddingBottom: 0 }}>
            <Typography variant="body14Regular">Signing data</Typography>
          </ListItem>
          <ListItem style={{ display: "list-item", paddingBottom: 0 }}>
            <Typography variant="body14Regular">Verify signed data</Typography>
          </ListItem>
          <ListItem style={{ display: "list-item", paddingBottom: 0 }}>
            <Typography variant="body14Regular">
              Encrypt and Decrypt data
            </Typography>
          </ListItem>
          <Typography variant="body14Regular">
            By implementing WSOP, the institution can scale its HSM capacity to
            handle peak transaction volumes, ensuring that every transaction is
            encrypted and secured while maintaining compliance with Indonesia
            security regulations.
          </Typography>
        </List>
      </Grid>
      <Grid item lg={12} display={"flex"} justifyContent={"flex-end"} mt="20px">
        <Button variant="contained" size="large" onClick={handleWSOP}>
          Go to WSOP Demo
        </Button>
      </Grid>
    </Grid>
  );
};

export default WsopText;
