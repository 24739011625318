import { Box } from "@mui/system";
import "chart.js/auto";
import { Paper, Typography } from "@mui/material";
import DailyStatics from "../../components/molecules/dashboard/DailyStatics";
import HeaderDashboard from "./HeaderDashboard";
import DashboardGrid from "./DashboardGrid";
import { useGetDashboardInfoMutation } from "../../store/feature/service/dashboard";
import { useEffect } from "react";
import entrustLogo from "../../assets/logo/entrustWhite.png";
import dayjs from "dayjs";

const Dashboard = () => {
  const [ getDashboardInfo ,{ data }] = useGetDashboardInfoMutation();
  const dashboardData = data !== undefined ? data?.groups[0] : "";
  const currentDate = dayjs();
  const currentDateOnly = dayjs().format('YYYY-MM-DD');
  
  useEffect(()=> {
    console.log('data.groups ===> ',data)
  }, [data])

  useEffect(()=> {
    getDashboardInfo({})
  },[])

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
        padding: "40px",
      }}
    >
      <Typography variant="h2bold" sx={{ mb: 3 }}>
        Welcome, User
      </Typography>

      {/* <Typography variant="h5bold" sx={{ mb: 2 }}>
        {currentDateOnly}
      </Typography>

      <Typography variant="body14Semibold" sx={{ paddingLeft: 3 }}>
         Protection domain and key Information <br />
         Type : {dashboardData.type}
        <br />
        Group ID : {dashboardData.groupid}
        <br />
        Protected Domain : {dashboardData.protection} <br />
        Softcard Name : {dashboardData.name} <br />
        Keys Link : {dashboardData.keys}
        <br />
      </Typography> */}

      {/* <HeaderDashboard />
      <DashboardGrid /> */}
       <img 
      src={entrustLogo} 
      alt="Description of image" 
      style={{ 
        width: 180, 
        height: 50, 
        position: "absolute", 
        bottom: 0, 
        right: 0,
        margin: '10px' 
      }} 
    />
    </Box>
  );
};

export default Dashboard;
