import {
  FormControl,
  SxProps,
  Typography,
  useTheme,
  Skeleton,
} from "@mui/material";
import { Controller } from "react-hook-form";
import FormLabel from "../../../atoms/formLabel/index";
import { StyledInput } from "./elements";

interface Iprops {
  sxFC?: SxProps;
  sxFL?: SxProps;
  name: string;
  label?: string;
  errors?: any;
  control?: any;
  maxLength?: number;
  defaultValue: string | number;
  disabled?: boolean;
  showCounter?: boolean;
  required?: boolean;
  placeholder?: string;
  isLoading?: boolean;
}

export default function TextAreaForm(props: Iprops) {
  const {
    sxFC,
    sxFL,
    name,
    label,
    control,
    defaultValue,
    errors,
    maxLength = 50,
    disabled,
    showCounter = true,
    required = false,
    placeholder = "",
    isLoading = false,
  } = props;
  const { palette } = useTheme();
  return (
    <FormControl
      sx={{ flex: 1, display: "flex", marginBottom: "1.5rem", ...sxFC }}
      variant="standard"
    >
      {label?.length !== 0 && (
        <FormLabel htmlFor={name} sx={{ color: palette.neutral.dark, ...sxFL }}>
          {label}{" "}
          {!required && !disabled && (
            <span
              style={{
                marginLeft: "4px",
                color: palette.neutral.light,
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: "1.5rem",
                fontStyle: "italic",
              }}
            >
              (optional)
            </span>
          )}
          {required && label !== undefined && !disabled && (
            <span style={{ color: "#ef4545" }}> *</span>
          )}
        </FormLabel>
      )}
      {!isLoading && (
        <Controller
          defaultValue={defaultValue}
          control={control}
          name={name}
          render={({ field }) => (
            <>
              <StyledInput
                {...field}
                disabled={disabled}
                size="small"
                id={name}
                inputProps={{ maxLength, placeholder }}
                error={!!errors[name]}
                helperText={errors[name] && errors[name].message}
                rows={4}
                multiline
              />
              {showCounter && (
                <Typography
                  sx={{
                    alignSelf: "flex-end",
                    margin: 0,
                    marginTop: "8px",
                    color: palette.gray.dark,
                  }}
                  variant="caption12Regular"
                >
                  {field?.value?.toString().length} / {maxLength}
                </Typography>
              )}
            </>
          )}
        />
      )}
      {isLoading && (
        <Skeleton
          style={{
            height: "109px",
            borderRadius: "4px",
            display: "block",
            marginTop: "10px",
          }}
        />
      )}
    </FormControl>
  );
}
