import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
} from "../../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import DetailDataSkeleton from "../../../components/template/skeleton/DetailDataSkeleton";
import Swal from "sweetalert2";
import {
  useCreateTokenizeMutation,
  useGetPolicyNameMutation,
} from "../../../store/feature/service/tokenization";
import tokenize from "../../../utils/validation/tokenize";
import AddIcon from "@mui/icons-material/Add";
import { useCreateBatchDetokenizeMutation, useCreateBatchTokenizeMutation } from "../../../store/feature/service/batch";
import BatchTokenizeTable from "../../../components/molecules/batch/tokenize";

const BatchDetokenize = () => {
  // REGION: INIT VARIABLES
  const location = useLocation();
  const { pathname } = location;
  const [optKey, setOptKey] = useState([]);
  const [arrData, setArrData] = useState([]);
  const editFgroup = pathname.includes("edit");

  const [fields, setFields] = useState([{ policyName: "", plaintext: "" }]);

  const addMoreFields = () => {
    setFields([...fields, { policyName: "", plaintext: "" }]);
  };

  const [getPolicy, { isSuccess, isError, data, error }] =
    useGetPolicyNameMutation();
  const [
    createTokenize,
    {
      isSuccess: successTokenize,
      isError: errorTokenize,
      data: dataTokenize,
      error: dataErrorTokenize,
      isLoading: loadingTokenize,
    },
  ] = useCreateBatchDetokenizeMutation();

  // const { data: dataMaskPolicy } = useGetMaskPolicyDetailQuery(idParam);
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    policyName: string;
    plaintext: string;
    result: string;
  }>({
    reValidateMode: "onChange",
    // resolver: yupResolver(tokenize),
    defaultValues: {
      policyName: "",
      plaintext: "",
      result: "",
    },
  });

  // REGION: SET FORM DATA
  useEffect(() => {
    if (data) {
      setOptKey(
        data?.token_policies?.map((item: any) => ({
          label: item.name,
          value: item.name,
        }))
      );
    }
  }, [data]);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    getPolicy({ pagesize: 100 });
  }, []);

  // useEffect(() => {
  //   if (dataTokenize) {
  //     setValue("result", dataTokenize?.value);
  //   }
  // }, [dataTokenize]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    let tempVar = {
      policyName: e.policyName,
      tokenData: e.plaintext,
    };
    console.log("tempVar ===> ", JSON.stringify(e));
    console.log("tempVar ===> ", fields);
    const filteredData: any = [];
    Object.keys(e).forEach((key) => {
      if (key.startsWith("policyName")) {
        const index = key.replace("policyName", "");
        if (e[`policyName${index}`] && e[`plaintext${index}`]) {
          filteredData.push({
            policyName: e[`policyName${index}`],
            tokenData: e[`plaintext${index}`],
          });
        }
      }
    });

    // sa｠2［?Ｇ３％＊ｐＹ8Wkate
    // ce2７＂｀＝Ｅ｜9XOｈgekne
    console.log("filter data ===> ", filteredData);
    //TO DO be continue here
    createTokenize(filteredData);
  };

  useEffect(() => {
    if (errorTokenize) {
      const dataError: any = dataErrorTokenize;
      // console.log('wkwkwk ===> ', dataErrorSign?.data)
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: dataError?.data?.error,
      });
    }
  }, [errorTokenize]);

  // useEffect(() => {
  //   if (successSign) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success Sign",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Success Sign Key",
  //     }).then(() => navigate("/masking-policy"));
  //   }
  // }, [successSign]);
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={false}
      isLoadingEvent={loadingTokenize}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Batch Detokenize
        </Typography>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              <Grid item lg={5}>
                <SelectFormm
                  name={`policyName${index}`}
                  label="Policy Name"
                  defaultValue={0}
                  options={optKey}
                  placeholder="Please Select"
                  disabled={false}
                  isLoading={false}
                  control={control}
                  errors={errors}
                  required
                />
              </Grid>
              <Grid item lg={5}>
                <InputForm
                  name={`plaintext${index}`}
                  label="Tokenized Data"
                  placeholder="Type Tokenized Data"
                  disabled={false}
                  isLoading={false}
                  control={control}
                  errors={errors}
                  maxLength={1000}
                  required
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item lg={10} display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              type="button"
              color="warning"
              startIcon={<AddIcon />}
              onClick={addMoreFields}
            >
              Add More
            </Button>
          </Grid>
          <Grid item lg={10} display={"flex"} justifyContent={"flex-end"}>
            <Button variant="contained" type="submit">
              Detokenize All
            </Button>
          </Grid>
          <Grid item lg={10}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Result
            </Typography>
            <BatchTokenizeTable data={dataTokenize} />
          </Grid>
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default BatchDetokenize;
