import IRoutes from "../interfaces/IRoutes";
import LogEvents from "../pages/logEvents/LogEvents";
import BatchDetokenize from "../pages/tokenization/batch/BatchDetokenize";
import BatchMask from "../pages/tokenization/batch/BatchMask";
import BatchTokenize from "../pages/tokenization/batch/BatchTokenize";
import Detokenize from "../pages/tokenization/Detokenize";
import DetokenMask from "../pages/tokenization/DetokenMask";
import Masking from "../pages/tokenization/Masking";
import Tokenize from "../pages/tokenization/Tokenize";

const TokenizationRoute: Array<IRoutes> = [
    {
        element: <Tokenize />,
        path: "/tokenize",
    },
    {
        element: <Detokenize />,
        path: "/detokenize",
    },
    {
        element: <Masking />,
        path: "/masking",
    },
    {
        element: <DetokenMask />,
        path: "/detoken-mask",
    },
    {
        element: <BatchTokenize />,
        path: "/batch-tokenize",
    },
    {
        element: <BatchDetokenize />,
        path: "/batch-detokenize",
    },
    {
        element: <BatchMask />,
        path: "/batch-mask",
    },

];

export { TokenizationRoute };
