import { apiSlice } from "../api/apiSlice";

export const BatchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createBatchTokenize: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/tokenize/batch`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateBatchTokenize"],
    }),
    createBatchDetokenize: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/detokenize/batch`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateBatchDetokenize"],
    }),
    createBatchMask: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/mask/batch`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateBatchMask"],
    }),
  }),
});

export const {
  useCreateBatchTokenizeMutation,
  useCreateBatchDetokenizeMutation,
  useCreateBatchMaskMutation
} = BatchApiSlice;
