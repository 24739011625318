import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const BatchTokenizeTable = ({ data }: any) => {
  // REGION: INIT VARIABLES

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "No",
      flex: 1,
      minWidth: 30,
      headerClassName: "centered",
    },
    {
      field: "policyName",
      headerName: "Policy Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      minWidth: 250,
    },
  ];

  // REGION: INIT ROWS
  console.log("data form table ===> ", data);
  const dataRow = data !== undefined ? data : [];
  const rows = dataRow?.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    no: index + 1,
    policyName: row.policyName,
    value: row.value || `error: ${row.error}`
    // appName: row.sworldAppname,
    // curved: row.curve
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "350px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};

export default BatchTokenizeTable;
