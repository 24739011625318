import { Button, Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSubmitLoginMutation } from "../../store/feature/service/userApiSlice";
import { setWebHost } from "../../utils/storage";

const Tokenization = () => {
  const [submitLogin, { data, isSuccess, isError }] = useSubmitLoginMutation();
  const navigate = useNavigate();
  const handleTokenization = () => {
    setWebHost("TOKENIZATION");
    console.log("password ===> ", process.env.REACT_APP_TOKENIZATION_PASSWORD)
    console.log("username ===> ", process.env.REACT_APP_TOKENIZATION_USERNAME)
    submitLogin({
      password: process.env.REACT_APP_TOKENIZATION_PASSWORD,
      username: process.env.REACT_APP_TOKENIZATION_USERNAME,
    });
  };

  useEffect(()=> {
    if(isSuccess){
      navigate('/')
    }
  }, [isSuccess])
  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <Typography variant="body14Regular">
          Tokenization is a powerful data protection technique that replaces
          sensitive information, such as credit card numbers or personal
          identifiers, with unique, non-sensitive tokens. These tokens can be
          used within your systems without exposing the original sensitive data,
          significantly reducing the risk of data breaches and aiding in
          compliance with strict data protection regulations.
        </Typography>
      </Grid>
      <Grid item lg={6}>
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          Key Features
        </Typography>
        <List style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <ListItem style={{ display: "list-item" }}>
            <Typography variant="body14Regular">
              <strong>Enhanced Security:</strong> By converting sensitive data
              into tokens, tokenization minimizes the exposure of critical
              information, ensuring that even if tokens are intercepted, they
              are useless to attackers.
            </Typography>
          </ListItem>
          <ListItem style={{ display: "list-item" }}>
            <Typography variant="body14Regular">
              <strong>Data Compliance:</strong> Tokenization helps organizations
              comply with industry regulations such as PCI DSS, GDPR, and HIPAA
              by reducing the scope of sensitive data that needs to be
              protected.
            </Typography>
          </ListItem>
          <ListItem style={{ display: "list-item" }}>
            <Typography variant="body14Regular">
              <strong>Flexible Integration: </strong> The tokenization solution
              integrates seamlessly with existing systems and workflows,
              allowing businesses to protect their data without disrupting
              operations.
            </Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid item lg={6}>
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          Use Case
        </Typography>
        <Typography variant="body14Regular">
          The company manages massive daily transactions, handling customer’s
          critical and sensitive data such as:
        </Typography>
        <List style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <ListItem style={{ display: "list-item", paddingBottom: 0 }}>
            <Typography variant="body14Regular">
              Personally Identifiable Information (PII)
            </Typography>
          </ListItem>
          <ListItem style={{ display: "list-item", paddingBottom: 0 }}>
            <Typography variant="body14Regular">Payment record</Typography>
          </ListItem>
          <ListItem style={{ display: "list-item", paddingBottom: 0 }}>
            <Typography variant="body14Regular">Credit card number</Typography>
          </ListItem>
          <ListItem style={{ display: "list-item", paddingBottom: 0 }}>
            <Typography variant="body14Regular">Health records</Typography>
          </ListItem>
          <Typography variant="body14Regular">
            By implementing a tokenization solution, the company can replace
            each customer’s sensitive data (such as credit card number) with a
            unique token before it enters their systems. This ensures that even
            if their database is compromised, the stolen tokens are meaningless
            to cybercriminals.
          </Typography>
        </List>
      </Grid>
      <Grid item lg={12} display={"flex"} justifyContent={"flex-end"} mt="20px">
        <Button variant="contained" size="large" onClick={handleTokenization}>
          Go to Tokenization Demo
        </Button>
      </Grid>
    </Grid>
  );
};

export default Tokenization;
