import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import DnsIcon from '@mui/icons-material/Dns';
import DvrIcon from '@mui/icons-material/Dvr';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';

export const arrSidebarWSOP: Array<ISidebar> = [
  // {
  //   key: 'dashboard',
  //   name: 'Dashboard',
  //   path: '/',
  //   icon: <DashboardTwoToneIcon />,
  // },
  { 
    key: 'sign',
    name: 'Sign',
    path: '/sign',
    icon: <FeaturedPlayListIcon />,
  },
  { 
    key: 'verify',
    name: 'Verify',
    path: '/verify',
    icon: <DnsIcon />,
  },
  { 
    key: 'encrypt',
    name: 'Encrypt',
    path: '/encrypt',
    icon: <ManageAccountsIcon />,
  },
  { 
    key: 'decrypt',
    name: 'Decrypt',
    path: '/decrypt',
    icon: <DevicesOtherIcon />,
  },
  // { 
  //   key: 'log-events',
  //   name: 'Log Events',
  //   path: '/log-events',
  //   icon: <DvrIcon />,
  // },
];
