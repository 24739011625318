import { apiSlice } from "../api/apiSlice";

export const tokenizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPolicyName: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/token/policy`,
          method: "GET",
          params: params,
        };
      },
      invalidatesTags: ["GetPolicyName"],
    }),
    getMaskPolicyName: builder.mutation<any, any>({
        query: (params) => {
          return {
            url: `/mask/policy`,
            method: "GET",
            params: params,
          };
        },
        invalidatesTags: ["GetMaskPolicyName"],
      }),
    createTokenize: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/tokenize`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateTokenize"],
    }),
    createDetokenize: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/detokenize`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateDetokenize"],
    }),
    createMask: builder.mutation<any, any>({
        query: (body) => {
          return {
            url: `/mask`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CreateMask"],
      }),
      createDetokenMask: builder.mutation<any, any>({
        query: (body) => {
          return {
            url: `/detoken_mask`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CreateDetokenMask"],
      }),
  }),
});

export const {
  useGetPolicyNameMutation,
  useCreateTokenizeMutation,
  useCreateDetokenizeMutation,
  useGetMaskPolicyNameMutation,
  useCreateMaskMutation, 
  useCreateDetokenMaskMutation
} = tokenizationApiSlice;
