import { apiSliceWsop } from "../api/apiSliceWsop";

export const livenessDeviceApiSlice = apiSliceWsop.injectEndpoints({
  endpoints: (builder) => ({
    keyListSign: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/key/user`,
          params: params,
        };
      },
      invalidatesTags: ["KeyListSign"],
    }),
    createSign: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/sign`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateSign"],
    }),
    verify: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/verify`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Verify"],
    }),
    encrypt: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/encrypt`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Encrypt"],
    }),
    decrypt: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/decrypt`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Decrypt"],
    }),
    //   logout: builder.mutation<any, any>({
    //     query: body => {
    //         return ({
    //             url: `/logout`,
    //             method: 'POST'
    //         })
    //     },
    //     invalidatesTags:['logoutUser']
    // }),
  }),
});

export const {
  useKeyListSignMutation,
  useCreateSignMutation,
  useVerifyMutation,
  useEncryptMutation,
  useDecryptMutation
} = livenessDeviceApiSlice;
