import { encrypt } from "../../../utils/hash/password";
import { apiSlice } from "../api/apiSlice";
import { ILogin } from "../reducers/userReducer";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        submitLogin: builder.mutation<any, any>({
            query: credentials => {
                // const { Email, Password } = credentials;
                return ({
                    url: '/login_direct',
                    method: 'POST',
                    body: credentials,
                })
            },
            invalidatesTags:['submitLogin', 'GetDashboardInfo']
        })
    })
})

export const { useSubmitLoginMutation } = userApiSlice