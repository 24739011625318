import { Box } from "@mui/system";
import "chart.js/auto";
import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import DailyStatics from "../../components/molecules/dashboard/DailyStatics";
import { useGetDashboardInfoMutation } from "../../store/feature/service/dashboard";
import { useEffect, useState } from "react";
import entrustLogo from "../../assets/logo/entrust-text.png";
import autentikaLogo from "../../assets/logo/autentika-text.png";
import dayjs from "dayjs";
import { TabPanelProps } from "@mui/lab";
import WsopText from "./WsopText";
import Tokenization from "./Tokenization";

const Login = () => {
  const [value, setValue] = useState(0);
  const [getDashboardInfo, { data }] = useGetDashboardInfoMutation();
  const dashboardData = data !== undefined ? data?.groups[0] : "";
  const currentDate = dayjs();
  const currentDateOnly = dayjs().format("YYYY-MM-DD");

  useEffect(() => {
    console.log("data.groups ===> ", data);
  }, [data]);

  useEffect(() => {
    getDashboardInfo({});
  }, []);

  //#region UI VIEW

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other }: any = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabPanel = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="basic tabs example"
            sx={{
              fontWeight: "bold",
            }}
          >
            <Tab label="WSOP" {...a11yProps(0)} />
            <Tab label="Tokenization" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <WsopText />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Tokenization />
        </CustomTabPanel>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        mt: 4,
        padding: "40px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <img
            src={autentikaLogo}
            alt="Description of image"
            style={{
              width: 400,
              height: 110,
              // position: "absolute",
              margin: "10px",
            }}
          />
        </Grid>
        <Grid item lg={6} display={"flex"} justifyContent={"flex-end"}>
          <img
            src={entrustLogo}
            alt="Description of image"
            style={{
              width: 400,
              height: 110,
              // position: "absolute",
              margin: "10px",
            }}
          />
        </Grid>
        <Grid item lg={12}>
          {tabPanel()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
