import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import Swal from "sweetalert2";
import {
  useCreateSignMutation,
  useEncryptMutation,
  useKeyListSignMutation,
} from "../../store/feature/service/wsop";
import encrypt from "../../utils/validation/encrypt";

const Encrypt = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { pathname } = location;
  const [optKey, setOptKey] = useState([]);
  const editFgroup = pathname.includes("edit");
  const { editDataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.satpasDevice);
  const { uc_ocsp_config_uid } = editDataOcspConfig;

  const [keyListSign, { isSuccess, isError, data, error }] =
    useKeyListSignMutation();
  const [
    encryptKey,
    { isSuccess: successEncrypt, isError: errorEncrypt, data: dataEncrypt, error: errorDataEncrypt },
  ] = useEncryptMutation();

  //     ,
  const optAlgo = [
    {
      label: "RSA1_5",
      value: "RSA1_5",
    },
    {
      label: "RSA-OAEP",
      value: "RSA-OAEP",
    },
    {
      label: "RSA-OAEP-256",
      value: "RSA-OAEP-256",
    },
    {
      label: "RSA-OAEP-384",
      value: "RSA-OAEP-384",
    },
    {
      label: "RSA-OAEP-512",
      value: "RSA-OAEP-512",
    },
    {
      label: "A128CBC",
      value: "A128CBC",
    },
    {
      label: "A192CBC",
      value: "A192CBC",
    },
    {
      label: "A256CBC",
      value: "A256CBC",
    },
    {
      label: "A128GCM",
      value: "A128GCM",
    },
    {
      label: "A192GCM",
      value: "A192GCM",
    },
    {
      label: "A256GCM",
      value: "A256GCM",
    },
    {
      label: "A128CBC-NOPAD@entrust.com",
      value: "A128CBC-NOPAD@entrust.com",
    },
    {
      label: "A192CBC-NOPAD@entrust.com",
      value: "A192CBC-NOPAD@entrust.com",
    },
    {
      label: "A256CBC-NOPAD@entrust.com",
      value: "A256CBC-NOPAD@entrust.com",
    },
  ];

  // const { data: dataMaskPolicy } = useGetMaskPolicyDetailQuery(idParam);
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    key: string;
    algorithm: string;
    inputVal: string;
    result: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(encrypt),
    defaultValues: {
      key: "",
      algorithm: "",
      inputVal: "",
      result: "",
    },
  });

  // REGION: SET FORM DATA
  useEffect(() => {
    if (data) {
      setOptKey(
        data.map((item: any) => ({
          label: item.sworldIdent,
          value: item.kid,
        }))
      );
    }
  }, [data]);


  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    keyListSign({});
  }, []);

  useEffect(()=> {
    if(dataEncrypt){
      setValue('result', dataEncrypt?.ciphertext)
    }
  }, [dataEncrypt])

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    let tempVar = {
      kid: e.key,
      alg: e.algorithm,
      plaintext: e.inputVal,
    };
    encryptKey(tempVar);
  };

  useEffect(() => {
    if (errorEncrypt) {
      const dataError: any = errorDataEncrypt;
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: dataError?.data?.error,
      });
    }
  }, [errorEncrypt]);

  // useEffect(() => {
  //   if (successEncrypt) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success Sign",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Success Sign Key",
  //     }).then(() => navigate("/masking-policy"));
  //   }
  // }, [successEncrypt]);
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Encrypt
        </Typography>
        <SelectFormm
          name="key"
          label="Key"
          defaultValue={0}
          options={optKey}
          placeholder="Please Select"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <SelectFormm
          name="algorithm"
          label="Algorithm"
          defaultValue={0}
          options={optAlgo}
          placeholder="Please Select"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <TextAreaForm
          name="inputVal"
          label="Text"
          placeholder="Type Input Value"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={1000}
          required
          defaultValue={""}
        />

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button variant="contained" type="submit">
            Encrypt
          </Button>
        </Stack>

        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Result
        </Typography>
        <TextAreaForm
          name="result"
          placeholder="Type Input Value"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={1000}
          required
          defaultValue={""}
        />
      </Box>
    </DetailDataSkeleton>
  );
};

export default Encrypt;
