
import IRoutes from '../interfaces/IRoutes';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/notFound/NotFound';
import MainContainer from '../components/template/mainContainer/MainContainer';
import Profile from '../pages/profile/Profile';
import Login from '../pages/login/Login';
import { LogEventsRoute } from './logEvents';
import DailyStatics from '../components/molecules/dashboard/DailyStatics';
import ChangePassword from '../pages/profile/ChangePassword';
import { ImportKeyRoute } from './importKey';
import Sign from '../pages/wsop/Sign';
import Encrypt from '../pages/wsop/Encrypt';
import Decrypt from '../pages/wsop/Decrypt';
import Verify from '../pages/wsop/Verify';
import { TokenizationRoute } from './tokenization';

const routes: IRoutes[] = [
  {
    element: <MainContainer />,
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        element: <DailyStatics />,
        path: '/statistics'
      },
      {
        element: <Login />,
        path: '/home',
      },
      {
        element: <Profile />,
        path: '/profile',
      },
      {
        element: <Sign />,
        path: '/sign',
      },
      {
        element: <Encrypt />,
        path: '/encrypt',
      },
      {
        element: <Decrypt />,
        path: '/decrypt',
      },
      {
        element: <Verify />,
        path: '/verify',
      },
      {
        element: <ChangePassword />,
        path: '/change-password',
      },
      ...ImportKeyRoute,
      ...LogEventsRoute,
      ...TokenizationRoute
    ],
  },
];

export default routes;
