import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  clearStorage,
  getRefreshToken,
  getToken,
} from "../../../utils/storage";
import Swal from "sweetalert2";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_WSOP}/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getToken();
    if (process.env.NODE_ENV === "development") {
      headers.set("x-mock-response-code", "200");
    }
    if (token === "RYq1PbGPou73ia1g0DQqFzZ-v4Ggf_dF") {
      headers.set("Authorization", token);
    } else {
      headers.set("Authorization", `${token}`);
    }
    // console.log('token ===> ')
    // if (endpoint === "logout") {
    //   headers.set("Refresh_token", refreshToken);
    // }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  // console.log('result here brother ==> ', result?.error?.status)
  // let error: any = result?.error?.status;
  let error: any = result?.error?.data;
  // if (error === 401) {
  //   clearStorage();
  //   window.location.href = "/login";
  // }
  //   return result;

  if (error?.error === "user is not authorized to this application") {
    clearStorage();
    window.location.href = "/home";
  } else if (error?.error === "unauthorized user role") {
    Swal.fire({
      icon: "error",
      title: "Unauthorized Role",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "unauthorized user role",
    }).then(() => {
      clearStorage();
      window.location.href = "/home";
    });
  }
  return result;
};

export const apiSliceWsop = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    "KeyListSign", // new for signing
    "CreateSign",
    "Verify",
    "Encrypt",
    "Decrypt"
  ],
});

export const { endpoints, reducerPath, reducer, middleware } = apiSliceWsop;
