import { createSlice } from "@reduxjs/toolkit";
import { getToken, setRefreshToken, setToken } from "../../../utils/storage";
import { userApiSlice } from "../service/userApiSlice";

export interface ILogin {
    email: string;
    password: string;
    showPass: boolean;
  }
  
  export interface IAccess {
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
    uid: string;
  }
  
  export const initialAccess: IAccess = {
    create: false,
    read: false,
    update: false,
    delete: false,
    uid: ""
  };
  
  export interface ISubmitLogin {
    rejectMessage: string;
    isLoading: boolean;
    name: string;
    roleTitle: string;
    accesToken: string;
    adminRole: any;
    accessSidebar: any;
    ocspConfig: any;
  }
  
  const initialState: ISubmitLogin = {
    rejectMessage: "",
    isLoading: false,
    name: "",
    roleTitle: "",
    accesToken: getToken(),
    adminRole: {},
    accessSidebar: [],
    ocspConfig: initialAccess,
  };

  
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      removeToken: (state) => {
        state.accesToken = "";
      }
    },
    extraReducers(builder) {
      builder.addMatcher(
        userApiSlice.endpoints.submitLogin.matchFulfilled,
        (state: any, action: any) => {
          console.log('data ===> ', action.payload)
          const token = action.payload.access_token;
          state.accesToken = token
          setToken(token);
        }
      );
    }
  });
    
export const { removeToken } = userSlice.actions;

export const userReducer = userSlice.reducer