import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  clearStorage,
  getRefreshToken,
  getToken,
} from "../../../utils/storage";
import Swal from "sweetalert2";

const token = getToken();
// export const urlBase = (tokens: any)=> {
//   if(tokenComp){
//     return `${process.env.REACT_APP_BASE_WSOP}/`
//   } else {
//     return `/`
//   }
// }

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_TOKENIZATION}/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getToken();
    const refreshToken = getRefreshToken();
    if (process.env.NODE_ENV === "development") {
      headers.set("x-mock-response-code", "200");
    }
    if (token) {
      headers.set("X-TOKEN-AUTH", `${token}`);
    }

    // console.log('token ===> ')
    // if (endpoint === "logout") {
    //   headers.set("Refresh_token", refreshToken);
    // }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  // console.log('result here brother ==> ', result?.error?.status)
  // let error: any = result?.error?.status;
  let error: any = result?.error?.data;
  // if (error === 401) {
  //   clearStorage();
  //   window.location.href = "/login";
  // }
  //   return result;

  if (
    error?.error === "tokenization Error: Access token expired" ||
    error?.error === "error on finding user" ||
    error?.error === "token is expired" ||
    error?.error === "user is not authorized to this application"
  ) {
    clearStorage();
    window.location.href = "/home";
  } else if (error?.error === "unauthorized user role") {
    Swal.fire({
      icon: "error",
      title: "Unauthorized Role",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "unauthorized user role",
    }).then(() => {
      clearStorage();
      window.location.href = "/home";
    });
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    "GetAllOcspList",
    "GetAdminManagementList",
    "GetLicenseServerList",
    "GetLivenessDeviceList",
    "GetLogEventsList",
    "GetDashboardInfo",
    "postGenerateKey",
    "GetKeysList",
    "DeleteKey",
    "UpdatePassword",
    "logoutUser",
    "GetImportKeysList",
    "DeleteImportKey",
    "ExportRsa",
    "ExportEcdsa",
    "ImportEcdsa",
    "ImportRsa",
    "submitLogin",
    "GetDashboardInfo",
    "KeyListSign", // new for signing
    "GetPolicyName",
    "CreateTokenize",
    "CreateDetokenize",
    "GetMaskPolicyName",
    "CreateMask",
    "CreateDetokenMask",
    "CreateBatchTokenize",
    "CreateBatchDetokenize",
    "CreateBatchMask"
  ],
});

export const { endpoints, reducerPath, reducer, middleware } = apiSlice;
