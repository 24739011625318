import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  SelectFormm,
  TextAreaForm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import Swal from "sweetalert2";
import {
  useCreateSignMutation,
  useKeyListSignMutation,
  useVerifyMutation,
} from "../../store/feature/service/wsop";
import verify from "../../utils/validation/verify";

const Verify = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { pathname } = location;
  const [optKey, setOptKey] = useState([]);
  const editFgroup = pathname.includes("edit");

  const [keyListSign, { isSuccess, isError, data, error }] =
    useKeyListSignMutation();
  const [
    verifyKey,
    { isSuccess: successVerify, isError: errorVerify, data: dataVerify },
  ] = useVerifyMutation();

  const optAlgo = [
    {
      label: "RS256",
      value: "RS256",
    },
    {
      label: "RS384",
      value: "RS384",
    },
    {
      label: "RS512",
      value: "RS512",
    },
    {
      label: "PS256",
      value: "PS256",
    },
    {
      label: "PS384",
      value: "PS384",
    },
    {
      label: "PS512",
      value: "PS512",
    },
    {
      label: "HS256",
      value: "HS256",
    },
    {
      label: "HS384",
      value: "HS384",
    },
    {
      label: "HS512",
      value: "HS512",
    },
    {
      label: "ES256",
      value: "ES256",
    },
    {
      label: "ES384",
      value: "ES384",
    },
    {
      label: "ES512",
      value: "ES512",
    },
    {
      label: "ECDSA-SHA256",
      value: "ECDSA-SHA256",
    },
    {
      label: "ECDSA-SHA384",
      value: "ECDSA-SHA384",
    },
    {
      label: "ECDSA-SHA512",
      value: "ECDSA-SHA512",
    },
  ];

  // const { data: dataMaskPolicy } = useGetMaskPolicyDetailQuery(idParam);
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    key: string;
    algorithm: string;
    inputVal: string;
    result: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(verify),
    defaultValues: {
      key: "",
      algorithm: "",
      inputVal: "",
      result: "",
    },
  });

  // REGION: SET FORM DATA
  useEffect(() => {
    if (data) {
      setOptKey(
        data.map((item: any) => ({
          label: item.sworldIdent,
          value: item.kid,
        }))
      );
    }
  }, [data]);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    keyListSign({});
  }, []);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    let tempVar = {
      kid: e.key,
      alg: e.algorithm,
      payload: e.inputVal,
      signature: e.result,
    };
    console.log("mantapuun ==> ", tempVar);
    verifyKey(tempVar);
  };

  useEffect(() => {
    if (errorVerify) {
      const dataError: any = errorVerify;
      Swal.fire({
        icon: "error",
        title: "Verification Fail",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "The verification of the signed information has failed. Please check the data integrity or try the process again.",
      });
    }
  }, [errorVerify]);

  useEffect(() => {
    if (successVerify) {
      Swal.fire({
        icon: "success",
        title: "Verification Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Your verification process has been completed successfully. You may now proceed with the next steps.",
      })
    }
  }, [successVerify]);
  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={false}
      isLoadingEvent={false}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Verify
        </Typography>
        <SelectFormm
          name="key"
          label="Key"
          defaultValue={0}
          options={optKey}
          placeholder="Please Select"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <SelectFormm
          name="algorithm"
          label="Algorithm"
          defaultValue={0}
          options={optAlgo}
          placeholder="Please Select"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <TextAreaForm
          name="inputVal"
          label="Payload"
          placeholder="Type Payload Here"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={1000}
          required
          defaultValue={""}
        />
        <TextAreaForm
          name="result"
          label="Signature"
          placeholder="Type Signature"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={1000}
          required
          defaultValue={""}
        />

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button variant="contained" type="submit">
            Verify
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default Verify;
