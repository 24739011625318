import { ISidebar } from "../interfaces/ISidebar";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone"; // dashboard
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import DnsIcon from "@mui/icons-material/Dns";
import DvrIcon from "@mui/icons-material/Dvr";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";

export const arrSidebar: Array<ISidebar> = [
  {
    key: "tokenize",
    name: "Tokenize",
    path: "/tokenize",
    icon: <DashboardTwoToneIcon />,
  },
  {
    key: "detokenize",
    name: "Detokenize",
    path: "/detokenize",
    icon: <FeaturedPlayListIcon />,
  },
  {
    key: "masking",
    name: "Masking",
    path: "/masking",
    icon: <DnsIcon />,
  },
  {
    key: "detoken-mask",
    name: "Detoken Mask",
    path: "/detoken-mask",
    icon: <DevicesOtherIcon />,
  },
  {
    key: "batch-operation",
    name: "Batch Operation",
    path: "",
    icon: <DvrIcon />,
    children: [
      {
        key: "batch-tokenize",
        name: "Batch Tokenize",
        path: "/batch-tokenize",
        icon: <DvrIcon />,
      },
      {
        key: "batch-detokenize",
        name: "Batch Detokenize",
        path: "/batch-detokenize",
        icon: <DvrIcon />,
      },
      {
        key: "batch-mask",
        name: "Batch Mask",
        path: "/batch-mask",
        icon: <DvrIcon />,
      },
    ],
  },
];
